import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../Component/Desktop/Navbar/Navbar";
import "./HomePage.scss";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";

const HomePage = ({ navigateForm }: { navigateForm: Function }) => {
  const navigate = useNavigate();
  const partnersArray = [
    "./images/insurers/aditya_birla_capital.svg",
    "./images/insurers/HDFC-ERGO-health.svg",
    "./images/insurers/Tata-AIA-Life-Insurance-Company-Limited.svg",
    "./images/insurers/HDFC-Standard-Life-Insurance-Co-Ltd.svg",
    "./images/insurers/kotak_general_insurance.svg",
    "./images/insurers/Liberty-Videocon-General-Insurance-Co-Ltd.svg",
    "./images/insurers/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg",
    "./images/insurers/Raheja-QBE.svg",
    "./images/insurers/Royal_Sundaram.svg",
  ];

  const settings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    dots: true,
  };

  const textimonialArray = [
    {
      name: "Walsa Jones",
      review:
        "I trust Ahalia for my insurance requirements. They stand by you when you have a claim.",
    },
    {
      name: "Sangeetha Nair",
      review:
        "I got excellent service when I had a claim for my car. They had arranged for cashless facility even though I did not take the policy from the dealership.",
    },
    {
      name: "Radhakrishnan",
      review:
        "I received best advice for my personal health insurance and the best service while I had a hospitalisation claim.",
    },
  ];
  return (
    <Box className="HomePageWrapper">
      <Navbar />

      <Box className="product-header">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"} marginBottom={"40px"}>
            <h5>LIFE & BEYOND</h5>
            <h2>
              We Provide <span>Solutions Formulated With Care</span> To Protect
              Your Wealth And Family.
            </h2>
          </Grid>
          <Grid xs={12}>
            <Box className="products-box">
              <ul className="productList">
                <li
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.HEALTH_FORM}`;
                  }}
                >
                  <Link className="healthproduct">
                    <img src="./images/health-product-icon.svg" alt="" />
                    <h6>Health Insurance</h6>
                    <p>Individual, Family & Parents</p>
                  </Link>
                  <span>
                    covers <strong>COVID-19</strong>
                  </span>
                </li>
                <li
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TERM_FORM}`;
                  }}
                >
                  <Link className="termproduct">
                    <img src="./images/term-product-icon.svg" alt="" />
                    <h6>Term Insurance </h6>
                    <p>Save tax upto 75,000</p>
                  </Link>
                  <span>
                    covers <strong> COVID-19</strong>
                  </span>
                </li>
                <li className="disabled">
                  <Link className="investmentproduct">
                    <img src="./images/investment-product-icon.svg" alt="" />
                    <h6>Investment Plans </h6>
                    <p>ULIP & Guaranteed</p>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.CAR_FORM}`;
                  }}
                >
                  <Link className="carproduct">
                    <img src="./images/car-product-icon.svg" alt="" />
                    <h6>Car Insurance </h6>
                    <p>New, Renew & Expired</p>
                  </Link>
                </li>
                <li
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TW_FORM}`;
                  }}
                >
                  <Link className="bikeproduct">
                    <img src="./images/bike-product-icon.svg" alt="" />
                    <h6>Bike Insurance </h6>
                    <p>New, Renew & Expired</p>
                  </Link>
                </li>
                <li className="disabled">
                  <Link className="travelproduct">
                    <img src="./images/travel-product-icon.svg" alt="" />
                    <h6>Travel Insurance </h6>
                    <p>Individual & Group</p>
                  </Link>
                </li>
              </ul>

              <Box className="irda-box">
                <img src="./images/irda-logo.png" alt="" />
                <p className="licence">
                  Licensed by
                  <span>IRDA/ DB 749/18</span>
                </p>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="work-section">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"} marginBottom={"54px"}>
            <h2>
              How to Work With <span>Policy First ?</span>
            </h2>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={6}>
            <img src="./images/work-poilcy.svg" alt="" className="work-img" />
          </Grid>
          <Grid xs={6}>
            <Box className="right-section">
              <ul className="workPolicy-list">
                <li>
                  <span className="step1">
                    step <strong>01</strong>
                  </span>
                  <Box>
                    <h5>Choose The Best Plan That Suits You</h5>
                    <p>
                      No pressure for targets and no minimum business volume for{" "}
                    </p>
                  </Box>
                </li>
                <li>
                  <span className="step2">
                    step <strong>02</strong>
                  </span>
                  <Box>
                    <h5>Choose The Best Plan That Suits You</h5>
                    <p>
                      No pressure for targets and no minimum business volume for{" "}
                    </p>
                  </Box>
                </li>
                <li>
                  <span className="step3">
                    step <strong>03</strong>
                  </span>
                  <Box>
                    <h5>Choose The Best Plan That Suits You</h5>
                    <p>
                      No pressure for targets and no minimum business volume for{" "}
                    </p>
                  </Box>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="claim_assistance_sec">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="claimBox">
              <Box className="left_box">
                <h2 className="mb-6">
                  Buy Online… <span>Get Serviced Locally…</span>
                </h2>
                <p>
                  We understand that in the unfortunate event of a claim, you
                  always look for assistance at your neighbourhood. We always
                  strive to ensure our presence at your locale when you are in
                  need. Should you require any claim service in your local area,
                  Our Point of Service Person in your area will provide the
                  necessary support and advice in the event of a claim.
                </p>
              </Box>
              <Box className="right_box">
                <img src="./images/claim.svg" alt="" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="products_sec">
        <Grid
          container
          columnSpacing={3}
          className="row ps_inner"
          justifyContent={"center"}
        >
          <Grid xs={12} textAlign={"center"}>
            <h2>
              Read About <span>Products</span>
            </h2>
            <ul className="product_list">
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                  <img src="./images/product_icon_car.svg" alt="" />
                  <span className="car">Car</span>
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                  <img src="./images/product_icon_bike.svg" alt="" />
                  <span className="bike">Bike</span>
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}>
                  <img src="./images/product_icon_health.svg" alt="" />
                  <span className="health">Health</span>
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                  <img src="./images/product_icon_term.svg" alt="" />
                  <span className="term">Term</span>
                </Link>
              </li>
              <li>
                <Link className="disabled">
                  <img src="./images/product_icon_investment.svg" alt="" />
                  <span className="investment">Investment</span>
                </Link>
              </li>
              <li>
                <Link className="disabled">
                  <img src="./images/product_icon_travel.svg" alt="" />
                  <span className="travel">Travel</span>
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="testimonials-sec">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"} marginBottom={"26px"}>
            <h2>
              What People <span>Say About Us</span>
            </h2>
          </Grid>
          <Grid xs={6}>
            <Slider {...settings}>
              {textimonialArray?.map((data, index) => (
                <Box paddingLeft={"12px"}>
                  <Box className="testimonial_slider">
                    <h5>{data.name}</h5>
                    <p>{data.review}</p>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Grid>
          <Grid xs={6}>
            <img
              src="./images/testimonial-pic.svg"
              alt=""
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="partners-sec">
        <Grid
          container
          columnSpacing={3}
          className="row"
          justifyContent={"center"}
        >
          <Grid xs={12} textAlign={"center"} marginBottom={"40px"}>
            <h2>
              Our <span>Partners</span>
            </h2>
          </Grid>
          <Grid xs={10} textAlign={"center"}>
            <ul className="partners-list">
              {partnersArray?.map((data, index) => (
                <li>
                  <img src={data} alt="" className="partner_img" />
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </Box>
  );
};

export default HomePage;
