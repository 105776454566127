import { Box, Button, Link, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import "./Navbar.scss";

import LoginContainer from "../../../Container/MyAccount/LoginContainer/LoginContainer";
import { MY_ACCOUNT_ROUTES } from "../../../Routing/Path/MyAccountRoutes";
import { useAppDispatch, useAppSelector } from "../../../Store/Store/hooks";
import { SESSION_CONSTANTS } from "../../../Services/Constants/SessionConstants";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { HomeSlice } from "../../../Store/Reducer/Home/HomeSlice";
import { UserProfileSlice } from "../../../Store/Reducer/Account/UserProfileSlice";

const Navbar = () => {
  const location = useLocation();

  const currentPath = location.pathname;
  const [openCustomerLogin, setOpenCustomerLogin] = useState(false);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const dispatch = useAppDispatch();
  const { USER_DATA } = useAppSelector((state) => state.USER_STATE);
  const handleLogout = () => {
    dispatch(UserProfileSlice.actions.setAccountUserDataMobile(""));
    sessionStorage.removeItem(SESSION_CONSTANTS.USER_MOBILE_NO);
    navigate("/");
  };

  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorE2);
  const producthandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const producthandleClose = () => {
    setAnchorE2(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box className="Navbar">
      <LoginContainer open={openCustomerLogin} setOpen={setOpenCustomerLogin} />
      <Box className="top-strip">
        <p>AHALIA INSURANCE BROKERS PRIVATE LIMITED </p>
      </Box>
      <Box className="logo-strip">
        <Grid container columnSpacing={3} className="row">
          <Grid xs="auto">
            <Link onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
              <img
                src="../images/policyfirst-logo.svg"
                alt="policyFirst-logo"
                className="logo"
                onClick={() => {
                  window.location.replace("/");
                }}
              />
            </Link>
          </Grid>
          <Grid xs className="nav-section text-right" alignSelf={"center"}>
            <ul>
              <li style={{ flexShrink: "0" }}>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Button
                  className="nav-link product-link"
                  id="basic-link"
                  aria-controls={open2 ? "basic-menu2" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={producthandleClick}
                >
                  Insurance
                </Button>
                <Menu
                  className="productMenu"
                  id="basic-menu2 "
                  anchorEl={anchorE2}
                  open={open2}
                  onClose={producthandleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-link",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                      producthandleClose();
                    }}
                  >
                    Health Insurance
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate(COMMON_ROUTES.PRODUCT_CAR);
                      producthandleClose();
                    }}
                  >
                    Car Insurance
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate(COMMON_ROUTES.PRODUCT_TW);
                      producthandleClose();
                    }}
                  >
                    Bike Insurance
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate(COMMON_ROUTES.PRODUCT_TERM);
                      producthandleClose();
                    }}
                  >
                    Term Insurance
                  </MenuItem>
                </Menu>
              </li>
              <li>
                <Link className="nav-link">POS</Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.CONTACT)}
                >
                  Contact
                </Link>
              </li>
              {/* <li className="ctaBtn">
                <Button
                  className="mediumPrimaryBtn rounded"
                  id="basic-button"
                  aria-controls={anchorEl ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorEl)}
                  onClick={(e) => {
                    handleClick(e);
                  }}
                >
                  Hi! User
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      // navigate(MY_ACCOUNT_ROUTES.DASHBOARD);
                      handleClose();
                    }}
                  >
                    My account
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </li> */}
              {USER_DATA.mobile.value ? (
                <li className="ctaBtn">
                  <Button
                    className="greenMdBtn rounded"
                    id="basic-button"
                    aria-controls={anchorEl ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorEl)}
                    onClick={(e) => {
                      dispatch(HomeSlice.actions.setPageStatus("MY_ACCOUNT"));
                      handleClick(e);
                    }}
                  >
                    Hi! User
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      // onClick={() => {
                      //   handleClose();
                      //   window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${USER_DATA.mobile.value}`;
                      // }}
                      onClick={() => {
                        handleClose();
                        const encodedMobile = btoa(USER_DATA.mobile.value);
                        sessionStorage.setItem(
                          SESSION_CONSTANTS.USER_MOBILE_NO,
                          encodedMobile
                        );
                        window.location.href = `${FRONTEND_DOMAIN}${MY_ACCOUNT_ROUTES.DASHBOARD}${encodedMobile}`;
                      }}
                    >
                      My account
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </li>
              ) : (
                <li className="ctaBtn">
                  <CustomButton
                    onClickFunction={() => {
                      setOpenCustomerLogin(true);
                    }}
                    class_name="mediumSecondaryBtn"
                    text_name="Login"
                  />
                </li>
              )}
              {/* <li className="ctaBtn">
                <CustomButton
                  text_name="Login"
                  class_name="mediumSecondaryBtn"
                  onClickFunction={() => {
                    setOpenCustomerLogin(true);
                  }}
                />
              </li> */}
            </ul>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Navbar;
