import { Box, Button, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import "../../../../CommonSCSS/ModalPopup.scss";
import RKTextField from "../../../Common/FieldTypes/RKTextField/RKTextField";
import CustomButton from "../../../Common/CustomButton/CustomButton";

const CustomerLogin = ({
  open,
  setOpen,
  showEnterMobileSection,
  setShowEnterMobileSection,
  loginFields,
  setLoginFields,
  fieldsUpdateState,
  handleShowMessage,
  showMessage,
  resendOtp,
}: {
  open: boolean;
  setOpen: any;
  showEnterMobileSection: boolean;
  setShowEnterMobileSection: any;
  loginFields: any;
  setLoginFields: any;
  fieldsUpdateState: Function;
  handleShowMessage: Function;
  showMessage: Function;
  resendOtp: Function;
}) => {
  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent">
          <Box className="loginPopup pt-8 py-4 pb-6">
            <Button
              className="closePopup"
              onClick={() => {
                setOpen(false);
                setShowEnterMobileSection(true);
                setLoginFields({
                  enterMobile: { value: "", warning: false },
                  enterOtp: { value: "", warning: false },
                });
              }}
            />

            {showEnterMobileSection ? (
              <>
                <Grid
                  container
                  spacing={3}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid xs={12} textAlign="center">
                    <h3 className="mb-2">Welcome!</h3>
                    <p>Please Login to your Account</p>
                  </Grid>
                  <Grid xs={6} textAlign="center">
                    <RKTextField
                      class_name="inputField"
                      title={"Enter Mobile Number"}
                      value={loginFields.enterMobile.value}
                      attrName={[
                        "enterMobile",
                        "value",
                        loginFields,
                        setLoginFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={loginFields.enterMobile.warning}
                      error_message={" Enter Mobile Number"}
                      max_length={10}
                    />
                  </Grid>
                  <Grid xs={12} textAlign="center">
                    <CustomButton
                      text_name="Send OTP"
                      class_name="mediumSecondaryBtn"
                      onClickFunction={() => {
                        handleShowMessage();
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  container
                  spacing={3}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid xs={12} textAlign="center">
                    <h3 className="mb-2">One Time Password</h3>
                    <p style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'8px'}}>
                      OTP sent successfully on{" "}
                      <span>+91-{loginFields.enterMobile.value}</span>
                      <CustomButton
                        text_name="Edit"
                        class_name="smallSecondaryBtn"
                        onClickFunction={() => {
                          setShowEnterMobileSection(true);
                        }}
                      />
                    </p>
                  </Grid>
                  <Grid xs={6} textAlign="center">
                    <RKTextField
                      class_name="inputField"
                      title={"Enter OTP"}
                      value={loginFields.enterOtp.value}
                      attrName={[
                        "enterOtp",
                        "value",
                        loginFields,
                        setLoginFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={loginFields.enterOtp.warning}
                      error_message={" Enter OTP"}
                      max_length={4}
                    />
                  </Grid>
                  <Grid xs={12} textAlign="center">
                    <CustomButton
                      text_name="Verify OTP"
                      class_name="mediumSecondaryBtn"
                      onClickFunction={() => {
                        showMessage();
                      }}
                    />
                  </Grid>
                  <Grid xs={12} textAlign="center" className="ctaBtn">
                    <CustomButton
                      text_name=" Resend OTP"
                      class_name="smallSecondaryBtn"
                      onClickFunction={() => {
                        setLoginFields({
                          ...loginFields,
                          enterOtp: { value: "", warning: false },
                        });
                        resendOtp();
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomerLogin;
