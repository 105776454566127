export enum COMMON_ROUTES {
  HOME = "/",
  HEALTH_FORM = "/form/health-insurance-form",
  CAR_FORM = "/form/car-insurance-form",
  TW_FORM = "/form/two-wheeler-form",
  TERM_FORM = "/form/term-insurance-form",
  ABOUT_US = "/about-us",
  CONTACT = "/contact-us",
  CAREER = "/career",
  PRODUCT_HEALTH = "/health-insurance",
  PRODUCT_CAR = "/car-insurance",
  PRODUCT_TW = "/two-wheeler-insurance",
  PRODUCT_TERM = "/term-insurance",
  TERM_AND_CONDTION = "/terms-and-conditions",
  CLAIMS = "/claim",
  COMPLAINT = "/complaint",
  PRIVACY_POLICY = "/privacy-policy",
  DISCLAIMER = "/disclaimer",
  REFUND_AND_CANCELLATION = "/refund-and-cancellation",
  GRIEVANCE_POLICY = "/grievance-policy",
  SITE_MAP = "/site-map",
}
