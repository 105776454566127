import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { COMMON_ROUTES } from "../Path/CommonRoutes";
import HomeContainer from "../../Container/Home/HomeContainer";
import Aboutus from "../../Pages/Desktop/Aboutus/Aboutus";
import HealthProductPage from "../../Pages/Desktop/HealthProductPage/HealthProductPage";
import TermProductPage from "../../Pages/Desktop/TermProductPage/TermProductPage";
import CarProductPage from "../../Pages/Desktop/CarProductPage/CarProductPage";
import BikeProductPage from "../../Pages/Desktop/BikeProductPage/BikeProductPage";
import Claim from "../../Pages/Desktop/Claim/Claim";
import Career from "../../Pages/Desktop/Career/Career";
import Contact from "../../Pages/Desktop/Contact/Contact";
import Complaint from "../../Pages/Desktop/Complaint/Complaint";
import TermsAndConditions from "../../Pages/Desktop/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../../Pages/Desktop/PrivacyPolicy/PrivacyPolicy";
import Disclaimer from "../../Pages/Desktop/Disclaimer/Disclaimer";
import Cancellation from "../../Pages/Desktop/Cancellation/Cancellation";

function CommonRoutes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App">
      <Routes>
        <Route path={COMMON_ROUTES.HOME} element={<HomeContainer />} />
        <Route path={COMMON_ROUTES.ABOUT_US} element={<Aboutus />} />
        <Route
          path={COMMON_ROUTES.PRODUCT_HEALTH}
          element={<HealthProductPage />}
        />
        <Route
          path={COMMON_ROUTES.PRODUCT_TERM}
          element={<TermProductPage />}
        />
        <Route path={COMMON_ROUTES.PRODUCT_CAR} element={<CarProductPage />} />
        <Route path={COMMON_ROUTES.PRODUCT_TW} element={<BikeProductPage />} />
        {/* <Route path={COMMON_ROUTES.ren} element={<TWProductPage />} /> */}
        <Route path={COMMON_ROUTES.CLAIMS} element={<Claim />} />
        <Route path={COMMON_ROUTES.CAREER} element={<Career />} />
        <Route path={COMMON_ROUTES.CONTACT} element={<Contact />} />
        <Route path={COMMON_ROUTES.COMPLAINT} element={<Complaint />} />
        <Route
          path={COMMON_ROUTES.TERM_AND_CONDTION}
          element={<TermsAndConditions />}
        />
        <Route
          path={COMMON_ROUTES.PRIVACY_POLICY}
          element={<PrivacyPolicy />}
        />
        <Route path={COMMON_ROUTES.DISCLAIMER} element={<Disclaimer />} />
        <Route
          path={COMMON_ROUTES.REFUND_AND_CANCELLATION}
          element={<Cancellation />}
        />
        {/* <Route
          path={COMMON_ROUTES.PRIVACY_POLICY}
          element={<GrievancePolicy />}
        /> */}
      </Routes>
    </div>
  );
}

export default CommonRoutes;
